<template>
  <div>
    <ac-module-heading> Policies </ac-module-heading>
    <v-card id="policies-list" class="card-wrapper">
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <ac-company-select @change="onCompanySelect" />
          <ac-input
            v-model="searchQuery"
            placeholder="Search"
            outlined
            class="staff-list-search me-3"
          />
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <ac-button title="Download CSV" class="me-3" :icon="icons.mdiDownload" />
        </div>
      </v-card-text>
      <v-data-table
        :headers="policyHeaders"
        :items="policiesList"
        item-key="investmentClass"
        :loading="loading"
        class="text-no-wrap"
        :expanded.sync="expandPolicyRow"
        @click:row="checkExpandedRow"
        show-expand
        single-expand
      >
        <template #[`item.investmentClass`]="{ item }">
          <span class="font-weight-medium">{{ item.investmentClass }}</span>
        </template>

        <template #[`item.preclearing`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.preclearing }}</span>
          <ac-select
            v-else
            :items="policySelectOptions"
            class="selected-row-input"
            label="Yes"
            full-width
          >
          </ac-select>
        </template>

        <template #[`item.reporting`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.reporting }}</span>
          <ac-select
            v-else
            :items="policySelectOptions"
            class="selected-row-input"
            label="Yes"
            full-width
          >
          </ac-select>
        </template>

        <template #[`item.approvalPeriod`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.approvalPeriod }}</span>
          <ac-input v-else class="selected-row-input" type="number"> </ac-input>
        </template>

        <template #[`item.holdingPeriod`]="{ item }">
          <span v-if="!checkExpandedRow(item)">{{ item.holdingPeriod }}</span>
          <ac-input v-else class="selected-row-input" type="number"> </ac-input>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card class="my-2">
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="align-start">
                      <div class="d-flex flex-column">
                        <div class="text-h6">Approver Designation</div>
                        <v-switch
                          v-model="switch1"
                          label="Initial Approver only.If this is unchecked,the investment class will require
                        2 layers of approvers"
                          class="mt-0"
                          hide-details
                          @click.stop
                        ></v-switch>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider class="mb-2"></v-divider>
                      <div>
                        <v-row class="header">
                          <v-col cols="2" class="font-weight-bold"> Staff Name </v-col>
                          <v-col class="font-weight-bold"> Initial Approvers</v-col>
                          <v-col cols="3" class="font-weight-bold"> Subsequent Approvers</v-col>
                        </v-row>
                        <v-row class="record" v-for="n in 5" :key="n" align="baseline">
                          <v-col class="py-0" cols="2"> Complaince Test {{ n }} </v-col>
                          <v-col class="d-flex flex-wrap py-0">
                            <div class="mr-4">
                              <v-switch
                                v-model="switch2"
                                label="Requester can choose"
                                class="mt-0"
                                hide-details
                                @click.stop
                              ></v-switch>
                            </div>
                            <div class="d-flex">
                              <v-select label="Primary" class="company-select" outlined dense>
                              </v-select>
                              <v-select
                                label="Secondary"
                                class="ml-4 company-select"
                                outlined
                                dense
                              >
                              </v-select>
                            </div>
                          </v-col>
                          <v-col class="py-0" cols="2"> Set to not required</v-col>
                          <v-col class="py-0" cols="1">
                            <ac-icon-btn
                              :text="`Copy all approver settings to all investment classes`"
                              :icon="icons.mdiContentCopy"
                              color="success"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-actions>
                <ac-button title="Save" color="success" small />
                <ac-button
                  title="Cancel"
                  color="error"
                  outlined
                  small
                  @click="expandPolicyRow = []"
                />
              </v-card-actions>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiContentCopy, mdiDownload } from '@mdi/js'

export default {
  components: {
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcInput: () => import('@/components/AcInput.vue'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    AcCompanySelect: () => import('@/components/AcCompanySelect'),
  },
  data: () => ({
    icons: {
      mdiDownload,
      mdiContentCopy,
    },
    searchQuery: '',
    companies: '',
    loading: false,
    policyHeaders: [
      { text: 'INVESTMENT CLASS', value: 'investmentClass' },
      { text: 'PRECLEARING', value: 'preclearing' },
      { text: 'REPORTING', value: 'reporting' },
      { text: 'APPROVAL PERIOD', value: 'approvalPeriod' },
      { text: 'HOLDING PERIOD', value: 'holdingPeriod' },
    ],
    switch1: false,
    switch2: false,
    policiesList: [
      {
        investmentClass: 'Listed Securities',
        preclearing: 'Yes',
        reporting: 'Yes',
        approvalPeriod: '5',
        holdingPeriod: '30',
      },
      {
        investmentClass: 'Exchange Traded Derivatives',
        preclearing: 'Yes',
        reporting: 'Yes',
        approvalPeriod: '12',
        holdingPeriod: '30',
      },
    ],
    expandPolicyRow: [],
    policySelectOptions: ['Yes', 'No', 'Prohibited'],
  }),
  methods: {
    async onCompanySelect() {
      console.log('Fetching company')
    },
    checkExpandedRow(currentRow) {
      return currentRow.investmentClass === this.expandPolicyRow[0]?.investmentClass
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
  .selected-row-input {
    max-width: 150px;
  }
}
</style>
